var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "model-room"
  }, [_c('div', {
    staticClass: "tip"
  }, [_vm._v(" 支持jpg、png、gif格式，建议大小" + _vm._s(_vm.limitSize) + "M以内" + _vm._s(_vm.draggable ? "，拖动图片排序" : "") + " ")]), _c('f-upload', {
    ref: "uploader",
    attrs: {
      "title": "批量上传图片",
      "type": 7,
      "limitSize": _vm.limitSize,
      "isCompress": false,
      "defaultFileList": _vm.fileList,
      "accept": ".jpg,.jpeg,.png,.gif"
    },
    on: {
      "on-success": function onSuccess(fileList) {
        return _vm.uploadSuccess(fileList);
      }
    }
  }), _vm.imageList.length ? _c('div', {
    staticClass: "pic-list"
  }, [_c('ul', [_c('draggable', {
    staticClass: "tags-drag",
    attrs: {
      "disabled": !_vm.draggable
    },
    on: {
      "sort": _vm.dragSort
    },
    model: {
      value: _vm.imageList,
      callback: function callback($$v) {
        _vm.imageList = $$v;
      },
      expression: "imageList"
    }
  }, _vm._l(_vm.imageList, function (item) {
    return _c('li', {
      key: item.url
    }, [_c('el-image', {
      attrs: {
        "src": item,
        "preview-src-list": _vm.imageList
      }
    }, [_c('div', {
      staticClass: "loading",
      attrs: {
        "slot": "placeholder"
      },
      slot: "placeholder"
    }, [_c('i', {
      staticClass: "el-icon-loading"
    })]), _c('div', {
      staticClass: "loading",
      attrs: {
        "slot": "error"
      },
      slot: "error"
    }, [_c('i', {
      staticClass: "el-icon-loading"
    })])]), _c('p', [_c('span', {
      on: {
        "click": function click($event) {
          return _vm.del(item);
        }
      }
    }, [_c('i', {
      staticClass: "el-icon-delete"
    }), _vm._v(" 删除")])])], 1);
  }), 0)], 1)]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }