<template>
  <div class="model-room">
    <div class="tip">
      支持jpg、png、gif格式，建议大小{{ limitSize }}M以内{{
        draggable ? "，拖动图片排序" : ""
      }}
    </div>
    <f-upload
      ref="uploader"
      title="批量上传图片"
      :type="7"
      :limitSize="limitSize"
      :isCompress="false"
      :defaultFileList="fileList"
      accept=".jpg,.jpeg,.png,.gif"
      @on-success="fileList => uploadSuccess(fileList)"
    >
    </f-upload>
    <div class="pic-list" v-if="imageList.length">
      <ul>
        <draggable
          v-model="imageList"
          :disabled="!draggable"
          class="tags-drag"
          @sort="dragSort"
        >
          <li v-for="item in imageList" :key="item.url">
            <el-image :src="item" :preview-src-list="imageList">
              <div slot="placeholder" class="loading">
                <i class="el-icon-loading"></i>
              </div>
              <div slot="error" class="loading">
                <i class="el-icon-loading"></i>
              </div>
            </el-image>
            <p>
              <span @click="del(item)"
                ><i class="el-icon-delete"></i> 删除</span
              >
            </p>
          </li>
        </draggable>
      </ul>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import FUpload from "@/components/upload";
export default {
  components: { FUpload, draggable },
  props: {
    limitSize: {
      type: Number,
      default: 10
    },
    // 是否可拖拽
    draggable: {
      type: Boolean,
      default: true
    },
    defaultFileList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      imageList: [], // 文件列表
      fileList: [] // 上传的文件信息
    };
  },
  watch: {
    defaultFileList: {
      immediate: true,
      handler(arr) {
        arr = arr || [];
        this.imageList = this.$deepClone(arr);
        this.fileList = arr.map(url => ({ url }));
      }
    }
  },
  methods: {
    // 获取本次上传成功的图片
    getDiffFileList(fileList) {
      return fileList
        .filter(item => !this.defaultFileList.includes(item.url))
        .map(item => item.url);
    },
    // 上传成功后的回调
    uploadSuccess(fileList) {
      this.fileList = []; // 上传成功后，将上一次的上传列表置空，避免重复上传
      let flag = fileList.every(item => item.url);
      if (flag) {
        const diffArr = this.getDiffFileList(fileList);
        this.imageList = [...this.imageList, ...diffArr];
        this.$emit("onSuccess", diffArr, this.imageList);
      }
    },
    // 删除图片
    del(url) {
      const target = this.imageList.find(item => item === url);
      const index = this.imageList.findIndex(item => item === url);
      this.imageList.splice(index, 1);
      this.$emit("remove", target, this.imageList);
    },
    // 拖动排序
    dragSort(e) {
      this.$emit("drag", this.imageList);
    }
  }
};
</script>

<style scoped lang="scss">
.tip {
  color: #aaa;
  margin-bottom: 20px;
}
.pic-list {
  padding-top: 20px;
  border-top: 1px solid #eee;
  margin-top: 15px;
  .tags-drag {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    padding: 5px;
    border: 1px solid #eee;
    margin: 0 10px 10px 0;
    cursor: pointer;
    p {
      font-size: 13px;
      color: #aaa;
      text-align: center;
      line-height: 100%;
      padding: 8px 0 5px 0;
      &:hover {
        text-decoration: underline;
        color: $primary;
      }
    }
  }
  img {
    display: block;
  }

  .el-image {
    display: block;
    width: 122px;
    height: 106px;
    div {
      width: 100%;
      height: 100%;
    }
    ::v-deep .loading {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #fff;
    }
  }
}
</style>
